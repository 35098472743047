import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Characteristic from '../components/Characteristic'
import Usps from '../components/Usps'
import Gallery from '../components/Gallery'
import Addresses from '../components/Addresses'
import Hint from '../components/Hint'

import '../scss/styles.scss'

const AromaQueen = () => {
  return (
    <DefaultLayout
      title={siteData.aromaQueen.title}
      description={siteData.aromaQueen.description}
      activePage={Pages.AROMA_QUEEN}
    >
      <Section className="headline">
        <Container>
          <h1 className="title">{siteData.aromaQueen.headline}</h1>
          <h2 className="subheadline">{siteData.aromaQueen.subheadline}</h2>
          <Paragraph className="protection">
            {siteData.aromaQueen.protection}
          </Paragraph>
        </Container>
      </Section>

      <Usps
        imageSrc={`../${siteData.aromaQueen.uspImg}`}
        usps={siteData.aromaQueen.usps}
      />

      <Section className="infos">
        <Container>
          <Paragraph>
            <strong>„Aroma-Queen“ ®</strong> ist eine Herbsthimbeere mit einem
            exzellentem Geschmack. Die Früchte sind groß, sehr süß und besitzen
            ein feines Aroma, das an die Waldhimbeere erinnert. Die Ernte
            beginnt Mitte August und dauert ca. 8 Wochen. Bei schönem
            Herbstwetter kann man sogar bis zum ersten Frost ernten.
          </Paragraph>
          <Paragraph>
            <strong>„Aroma-Queen“ ®</strong> bringt Höchsterträge und übertrifft
            ihre Muttersorte „Autumn Blissi“ („Blissi“) im Geschmack und im
            Ertrag. Ihre Gesundheit und Widerstandsfähigkeit gegen Krankheiten
            hat sie von „Autumn Blissi“ („Blissi“) geerbt.{' '}
            <strong>„Aroma-Queen“ ®</strong> ist sehr frosthart.
          </Paragraph>
          <Paragraph>
            Die Früchte von <strong>„Aroma-Queen“ ®</strong> sind zum Einfrosten
            gut geeignet. Sie behalten auch nach dem Auftauen ihre schöne Form.{' '}
            <strong>„Aroma-Queen“ ®</strong> löst gut vom Zapfen.
          </Paragraph>
          <Paragraph className="is-last">
            <strong>„Aroma-Queen“ ®</strong> ist starkwachsend und sehr
            standfest. Sie benötigt in der Regel kein Gerüst.
          </Paragraph>
        </Container>
      </Section>

      <Section>
        <Container>
          <Gallery images={siteData.aromaQueen.images} />
        </Container>
      </Section>

      <Section className="characteristics">
        <Container>
          <h2>Anbauhinweise</h2>
          <Characteristic title="Bodenvorbereitung">
            „Aroma-Queen“ ® verlangt einen lockeren, mit Kompost oder
            Rindenmulch angereicherten Boden. Auf schweren Böden sollte sie auf
            einen Damm aus Kompost o. ä. gepflanzt werden. Da „Aroma-Queen“ ®
            sich sehr stark ausbreitet, ist eine Begrenzung mit 30 cm breiten
            Streifen (z. B. Noppenfolie aus dem Baumarkt) - 25 cm senkrecht
            eingegraben - sinnvoll.
          </Characteristic>
          <Characteristic title="Abstand in der Reihe">
            mindestens 50 cm
          </Characteristic>
          <Characteristic title="Pflanzzeit">
            Der beste Zeitpunkt für die Pflanzung ist das Frühjahr.
          </Characteristic>
          <Characteristic title="Pflegemaßnahmen" isLast>
            „Aroma-Queen“ ® trägt an der im Frühjahr ausgetriebenen einjährigen
            Rute. Nach der Ernte werden im späten Herbst (Nov./Dez.) alle Ruten
            ohne Ausnahme unmittelbar an der Erde abgeschnitten. Die Pflanzen
            treiben dann im nächsten Frühjahr aus dem Wurzelstock neu aus. (Ca.
            15 Ruten pro Pflanze stehen lassen, die übrigen entfernen.)
            <br />
            Wenn „Aroma-Queen“ ® auf diese Weise geschnitten wird, gibt es keine
            Maden und keine Rutenkrankheiten und der Himbeerbestand bleibt
            gesund. „Aroma-Queen“ ® wird virusfrei geliefert.
          </Characteristic>
        </Container>
      </Section>

      <Section className="infos">
        <Container>
          <h2>Krankheiten, Schädlinge und Pflegehinweise</h2>
          <h3>Himbeergallmücke (Lasioptera rubi)</h3>
          <Paragraph>
            Die Himbeergallmücke (Lasioptera rubi) ist ein winziges Insekt von
            nur etwa 2 mm Größe. Diese kleine Mücke kann einen beträchtlichen
            Schaden in der Himbeeranlage anrichten.
          </Paragraph>
          <Paragraph>
            Die Himbeergallmücke legt im Mai ihre Eier an den Himbeerruten ab,
            aus denen die Larven schlüpfen und sich dann in den Himbeertrieb
            bohren. Im August sind an den Himbeerruten Wucherungen (Gallen)
            sichtbar (Abb. 1), die durch die Larven hervorgerufen werden und die
            zu einem Absterben der Ruten oberhalb der Gallen führen.
            <br />
            Die Larven überwintern in den Gallen bis zum April (Abb. 2), dann
            verpuppen sich die Larven und schlüpfen als fertige Insekten aus und
            der Kreislauf beginnt von neuem.
          </Paragraph>
          <Gallery images={siteData.aromaQueen.imagesCare} />
          <Paragraph>
            Pflanzenschutzmittel sind gegen die Himbeergallmücke im Hausgarten
            nicht zugelassen. Die beste Methode ist das sofortige Abschneiden
            der Triebe über den Gallen, wenn sie entdeckt werden und das
            Entsorgen in der Mülltonne. Außerdem müssen auch wildwachsende
            Himbeeren und Brombeeren im Garten ständig auf Gallen durchgesehen
            werden, da diese sonst eine Quelle für einen neuen Befall in der
            Himbeeranlage darstellen.
          </Paragraph>
          <Paragraph className="is-last">
            Wenn Sie die mit Gallen befallenen Ruten regelmäßig entfernen,
            können Sie die Vermehrung der Himbeergallmücke stark einschränken.
          </Paragraph>
        </Container>
      </Section>

      <Hint hint={siteData.aromaQueen.hints[0]} />

      <Addresses
        title={siteData.aromaQueen.mailOrderTitle}
        type="mailOrder"
        addresses={siteData.aromaQueen.mailOrder}
      />

      <Hint
        hint={siteData.aromaQueen.hints[1]}
        hintUrl={siteData.aromaQueen.hintUrl.url}
      />

      <Addresses
        title={siteData.aromaQueen.licenseesTitle}
        type="licensees"
        addresses={siteData.aromaQueen.licensees}
      />
    </DefaultLayout>
  )
}

export default React.memo(AromaQueen)
