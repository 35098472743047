import React, { ReactChild } from 'react'

type CharacteristicProps = {
  title: string
  children: ReactChild | ReactChild[]
  isLast?: boolean
}

const Characteristic = ({ title, children, isLast }: CharacteristicProps) => {
  const classes = isLast ? 'characteristic is-last' : 'characteristic'

  return (
    <p className={classes}>
      <span className="characteristic__title">{title}:</span>
      {children}
    </p>
  )
}

export default React.memo(Characteristic)
